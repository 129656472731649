<template>
  <div v-if="content" :class="classes">
    <router-link
      :to="getContentDetailPath(content.recipe_id)"
      @click="onCardClick"
      v-ripple="isPublishable ? $utilities.getRippleOptions('light') : false">
      <div v-if="content.image_url" class="dp-brew-myrecipe-card__thumbnail">
        <img :src="content.image_url" :alt="content.name" @load="onImgLoaded" />
      </div>

      <div class="dp-brew-myrecipe-card__content">
        <div class="dp-brew-myrecipe-card__button">
          <DpMyrecipeButton
            padding-size="sm"
            :id="content.recipe_id"
            :state="content.is_bookmark" />
        </div>

        <h2 v-if="content.name" class="dp-brew-myrecipe-card__title">
          <!---->{{ content.name }}
        </h2>

        <div
          v-if="content.capsule && isPublishPeriod(content.capsule)"
          class="dp-brew-myrecipe-card__capsule">
          <Icon name="capsuleR" color="dpGrey66" :width="15" :height="15" />
          <span class="dp-brew-myrecipe-card__capsule-name">{{
            content.capsule.name
          }}</span>
        </div>

        <p
          v-if="
            content.barista &&
            isPublishPeriod(content.barista) &&
            content.barista.name_alphabetical
          "
          class="dp-brew-myrecipe-card__pro">
          <span class="dp-brew-myrecipe-card__pro-by">
            <template>by</template>
          </span>
          <span class="dp-brew-myrecipe-card__pro-name">{{
            content.barista.name_alphabetical
          }}</span>
        </p>
      </div>

      <p v-if="!isPublishable" class="dp-brew-myrecipe-card__closed">
        {{ WORDS.DRIP_POD_HISTORY_LIST.CLOSED }}
      </p>
    </router-link>
  </div>
</template>

<script>
import { WORDS } from '@/constants/words';
import { defineComponent, computed } from '@vue/composition-api';
import { useDate } from '@/composables/useDate';
const { formatDpDateTime } = useDate();
import { usePublishable } from '@/composables/usePublishable';

export default defineComponent({
  components: {
    DpMyrecipeButton: () =>
      import('@/components/dripPod/core/MyrecipeButton.vue')
  },

  props: {
    content: {
      type: Object,
      default: undefined
    }
  },

  setup(props, context) {
    const { isPublishPeriod } = usePublishable();
    const isPublishable = computed(() => isPublishPeriod(props.content));
    const getContentDetailPath = (id) =>
      isPublishable.value && id ? `/drip-pod/pro-recipe/detail/${id}` : '';
    const classes = computed(() => [
      'dp-brew-myrecipe-card',
      !isPublishable.value ? 'dp-brew-myrecipe-card--disabled' : false
    ]);

    const onCardClick = () => {
      if (!isPublishable.value) return;
      window.location.href = getContentDetailPath(props.content.recipe_id);
    };

    const onImgLoaded = () => {
      context.emit('on-img-loaded');
    };

    return {
      WORDS,
      isPublishable,
      getContentDetailPath,
      classes,
      formatDpDateTime,
      onImgLoaded,
      isPublishPeriod,
      onCardClick
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-brew-myrecipe-card {
  display: grid;
  grid-template-rows: 1fr;
  background-color: variables.$dpWhite01;
  border-bottom: 1px solid variables.$dpGreyEa;

  &__closed {
    grid-column: 1 / span 2;
    grid-row-start: 3;
    margin: 0 0 -8px !important;
    font-size: 10px;
  }

  &--disabled {
    background-color: variables.$dpGreyF1;

    > * {
      opacity: 0.7;
    }
  }

  &:nth-child(odd) {
    padding-left: 8px;
  }

  &:nth-child(even) {
    padding-right: 8px;
  }

  > * {
    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    position: relative;
    display: grid;
    place-items: start;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    row-gap: 8px;
    margin: 0;
    padding: 16px 8px;
    width: 100%;
    overflow: hidden;
    text-decoration: none;
    color: variables.$dpBlack01;

    > *:last-child {
      margin-bottom: 0;
    }
  }

  &__content {
    display: grid;
    place-content: start space-between;
    grid-template-columns: 1fr 17%;
    grid-row-start: 2;
    width: 100%;

    > * {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__button {
    display: grid;
    place-items: start end;
    grid-column-start: 2;
    grid-row-start: 1;
    margin-top: -8px;
  }

  &__taste {
    margin-bottom: 4px;
  }

  &__title {
    @include mixin.clamp(2);

    margin-bottom: 8px;
    font-size: 16px;
    line-height: 1.3;
    font-weight: bold;
    white-space: pre-wrap;
  }

  &__capsule {
    display: grid;
    place-items: start;
    grid-column: 1 / span 2;
    grid-row: 2;
    grid-template-columns: 16px 1fr;
    gap: 3px;
    margin-bottom: 8px;
    color: variables.$dpGrey66;
    font-size: 12px;

    &-name {
      @include mixin.clamp(1);

      line-height: 1.2;
    }
  }

  &__thumbnail {
    @include mixin.imageAspectRatio(164, 164);

    grid-row-start: 1;
    overflow: hidden;
  }

  &__pro {
    display: flex;
    justify-content: flex-start;
    grid-column: 1 / span 2;
    grid-row: 3;
    font-size: 12px;
    line-height: 1.2;
    color: variables.$dpGrey66;

    &-by,
    &-name {
      place-content: center end;
      text-align: left;
    }

    &-by {
      margin-right: 4px;
      min-width: 1.2em;
    }

    &-name {
      @include mixin.clamp(1);
    }
  }
}
</style>
