var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.content
    ? _c(
        "div",
        { class: _vm.classes },
        [
          _c(
            "router-link",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple",
                  value: _vm.isPublishable
                    ? _vm.$utilities.getRippleOptions("light")
                    : false,
                  expression:
                    "isPublishable ? $utilities.getRippleOptions('light') : false",
                },
              ],
              attrs: { to: _vm.getContentDetailPath(_vm.content.recipe_id) },
              on: { click: _vm.onCardClick },
            },
            [
              _vm.content.image_url
                ? _c(
                    "div",
                    { staticClass: "dp-brew-myrecipe-card__thumbnail" },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.content.image_url,
                          alt: _vm.content.name,
                        },
                        on: { load: _vm.onImgLoaded },
                      }),
                    ]
                  )
                : _vm._e(),
              _c("div", { staticClass: "dp-brew-myrecipe-card__content" }, [
                _c(
                  "div",
                  { staticClass: "dp-brew-myrecipe-card__button" },
                  [
                    _c("DpMyrecipeButton", {
                      attrs: {
                        "padding-size": "sm",
                        id: _vm.content.recipe_id,
                        state: _vm.content.is_bookmark,
                      },
                    }),
                  ],
                  1
                ),
                _vm.content.name
                  ? _c("h2", { staticClass: "dp-brew-myrecipe-card__title" }, [
                      _vm._v(_vm._s(_vm.content.name) + " "),
                    ])
                  : _vm._e(),
                _vm.content.capsule && _vm.isPublishPeriod(_vm.content.capsule)
                  ? _c(
                      "div",
                      { staticClass: "dp-brew-myrecipe-card__capsule" },
                      [
                        _c("Icon", {
                          attrs: {
                            name: "capsuleR",
                            color: "dpGrey66",
                            width: 15,
                            height: 15,
                          },
                        }),
                        _c(
                          "span",
                          {
                            staticClass: "dp-brew-myrecipe-card__capsule-name",
                          },
                          [_vm._v(_vm._s(_vm.content.capsule.name))]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.content.barista &&
                _vm.isPublishPeriod(_vm.content.barista) &&
                _vm.content.barista.name_alphabetical
                  ? _c("p", { staticClass: "dp-brew-myrecipe-card__pro" }, [
                      _c(
                        "span",
                        { staticClass: "dp-brew-myrecipe-card__pro-by" },
                        [[_vm._v("by")]],
                        2
                      ),
                      _c(
                        "span",
                        { staticClass: "dp-brew-myrecipe-card__pro-name" },
                        [_vm._v(_vm._s(_vm.content.barista.name_alphabetical))]
                      ),
                    ])
                  : _vm._e(),
              ]),
              !_vm.isPublishable
                ? _c("p", { staticClass: "dp-brew-myrecipe-card__closed" }, [
                    _vm._v(
                      " " + _vm._s(_vm.WORDS.DRIP_POD_HISTORY_LIST.CLOSED) + " "
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }